/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3d9ea938b6afa941-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Open_Sans_Fallback_9c011f';src: local("Arial");ascent-override: 101.65%;descent-override: 27.86%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_9c011f {font-family: '__Open_Sans_9c011f', '__Open_Sans_Fallback_9c011f';font-style: normal
}.__variable_9c011f {--font-openSans: '__Open_Sans_9c011f', '__Open_Sans_Fallback_9c011f'
}

.Button_button__LbREC {
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 0.5rem;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Button_button__LbREC:disabled {
    cursor: not-allowed;
  }

.Button_button__LbREC .Button_iconLeft__gNV_a + span {
    margin-left: 8px;
  }

.Button_button__LbREC .Button_iconRight__zKB5x {
    margin-left: 8px;
  }

.Button_button--variantSolid__W0FqF {
  background-color: #d22b50 !important;
  color: white !important;
}

.Button_button--variantSolid__W0FqF:focus {
    background-color: #d22b50 !important;
    box-shadow: inset 0px 0px 0px 2px #edaab9 !important;
  }

.Button_button--variantSolid__W0FqF:hover {
    background-color: #a82240 !important;
  }

.Button_button--variantSolid__W0FqF:active {
    background-color: #7e1a30 !important;
    box-shadow: none !important;
  }

.Button_button--variantSolid__W0FqF:disabled {
    color: #a8adb7 !important;
    background-color: #f5f6f9 !important;
  }

.Button_button--variantSoft__ofgfj {
  background-color: #f5f6f9;
  color: #2b50c8;
}

.Button_button--variantSoft__ofgfj:focus {
    background-color: #ffffff;
    color: #2b50c8;
    outline: 1px solid #5573d3;
    box-shadow: inset 0px 0px 0px 2px #aab9e9;
  }

.Button_button--variantSoft__ofgfj:hover {
    background-color: #eaedf9;
    color: #2240a0;
  }

.Button_button--variantSoft__ofgfj:active {
    background-color: #d5dcf4;
    color: #1a3078;
    outline: none;
    box-shadow: none;
  }

.Button_button--variantSoft__ofgfj:disabled {
    color: #a8adb7;
    background-color: #f5f6f9;
  }

.Button_button--variantOutline1__ew4y3 {
  border: 1px solid #d22b50;
  background-color: white;
  color: #d22b50;
}

.Button_button--variantOutline1__ew4y3:focus {
    background-color: #fbeaee;
    box-shadow: 0px 0px 0px 2px #edaab9;
  }

.Button_button--variantOutline1__ew4y3:hover {
    background-color: #fbeaee;
  }

.Button_button--variantOutline1__ew4y3:active {
    background-color: #f6d5dc;
    border-color: #a82240;
    color: #a82240;
    box-shadow: none;
  }

.Button_button--variantOutline1__ew4y3:disabled {
    color: #a8adb7;
    background-color: #f5f6f9;
    border: none;
  }

.Button_button--variantOutline2___WEX4 {
  border: 1px solid #e2e4e8;
  background-color: white;
  color: #344054;
}

.Button_button--variantOutline2___WEX4:focus {
    background-color: #f5f6f9;
    border-color: #344054;
    box-shadow: 0px 0px 0px 2px #a8adb7;
  }

.Button_button--variantOutline2___WEX4:hover {
    background-color: #f5f6f9;
    border-color: #ced2d8;
  }

.Button_button--variantOutline2___WEX4:active {
    background-color: #e2e4e8;
    border-color: #a8adb7;
    box-shadow: none;
  }

.Button_button--variantOutline2___WEX4:disabled {
    color: #a8adb7;
    background-color: #f5f6f9;
    border: none;
  }

.Button_button--variantTransparent__pCj5H {
  color: #2b50c8;
}

.Button_button--variantTransparent__pCj5H:focus {
    background-color: #ffffff;
    outline: 1px solid #5573d3;
    box-shadow: inset 0px 0px 0px 2px #aab9e9;
  }

.Button_button--variantTransparent__pCj5H:hover {
    background-color: #eaedf9;
  }

.Button_button--variantTransparent__pCj5H:active {
    color: #2240a0;
    background-color: #d5dcf4;
    outline: none;
    box-shadow: none;
  }

.Button_button--variantTransparent__pCj5H:disabled {
    color: #a8adb7;
    background-color: transparent;
  }

.Button_button--variantTransparentAccent__UNmPD {
  color: #d22b50;
}

.Button_button--variantTransparentAccent__UNmPD:focus {
    background-color: #ffffff;
    outline: 1px solid #db5573;
    box-shadow: inset 0px 0px 0px 2px #edaab9;
  }

.Button_button--variantTransparentAccent__UNmPD:hover {
    background-color: #fbeaee;
  }

.Button_button--variantTransparentAccent__UNmPD:active {
    color: #a82240;
    background-color: #f6d5dc;
    outline: none;
    box-shadow: none;
  }

.Button_button--variantTransparentAccent__UNmPD:disabled {
    color: #a8adb7;
    background-color: transparent;
  }

.Button_button--sizeSmall__A0HJw {
  min-height: 32px;
  min-width: 32px;
  padding: 5px 7px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--font-openSans);
}

.Button_button--sizeSmall__A0HJw.Button_button__LbREC svg {
    width: 12px;
    height: 12px;
  }

.Button_button--sizeMedium__D0924 {
  min-height: 40px;
  min-width: 40px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  font-family: var(--font-openSans);
}

.Button_button--sizeMedium__D0924.Button_button__LbREC svg {
    width: 16px;
    height: 16px;
  }

.Button_button--sizeLarge__9dBfx {
  min-height: 52px;
  min-width: 52px;
  padding: 13px 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--font-openSans);
}

.Button_button--sizeLarge__9dBfx.Button_button__LbREC svg {
    width: 20px;
    height: 20px;
  }

.Spinner_spinner__RExFV {
  animation: Spinner_rotation__aWCvL 0.5s infinite linear;
  border-radius: 100%;
  box-sizing: border-box;
  border-style: solid;
}

.Spinner_spinner__RExFV:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 100%;
  }

.Spinner_spinner__RExFV[data-weight='thin'] {
    border-width: 2px !important;
  }

.Spinner_spinner__RExFV[data-weight='thin']::before {
      top: -2px !important;
      border-width: 2px !important;
    }

.Spinner_spinner__RExFV[data-size='small'] {
    height: 21px;
    width: 21px;
    border-width: 3px;
  }

.Spinner_spinner__RExFV[data-size='small']::before {
      top: -3px;
      border-width: 3px;
    }

.Spinner_spinner__RExFV[data-size='medium'] {
    height: 42px;
    width: 42px;
    border-width: 6px;
  }

.Spinner_spinner__RExFV[data-size='medium']::before {
      top: -6px;
      border-width: 6px;
    }

.Spinner_spinner__RExFV[data-size='large'] {
    height: 64px;
    width: 64px;
    border-width: 10px;
  }

.Spinner_spinner__RExFV[data-size='large']::before {
      top: -10px;
      border-width: 10px;
    }

.Spinner_spinner__RExFV[data-color='primary'] {
    border-color: #fbeaee;
  }

.Spinner_spinner__RExFV[data-color='primary']::before {
      border-color: #d22b50 transparent transparent transparent;
    }

.Spinner_spinner__RExFV[data-color='secondary'] {
    border-color: #d5dcf4;
  }

.Spinner_spinner__RExFV[data-color='secondary']::before {
      border-color: #2b50c8 transparent transparent transparent;
    }

.Spinner_spinner__RExFV[data-color='tertiary'] {
    border-color: #f5f6f9;
  }

.Spinner_spinner__RExFV[data-color='tertiary']::before {
      border-color: #344054 transparent transparent transparent;
    }

@keyframes Spinner_rotation__aWCvL {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.Snackbar_mainContainer__R8R_V {
  box-shadow: 0px 4px 20px 0px #00000026, 0px 0px 3px 0px #0000001a;
  border-radius: 0.5rem;

  box-sizing: border-box;
  padding: 16px 24px 16px 24px;

  display: flex;
  align-items: center;
  justify-items: center;

  gap: 0.75rem;

  width: 600px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
}

.Snackbar_mainContainer__R8R_V[data-variant='success'] {
    background-color: #ecfdf3;
  }

.Snackbar_mainContainer__R8R_V[data-variant='error'] {
    background-color: #fee4e2;
  }

.Snackbar_iconContainer__XHtge {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.Snackbar_iconContainer__XHtge .Snackbar_icon__cuoLQ {
    width: 33.61px;
    height: 33.61px;
    fill: #344054;
    color: white;
  }

[data-variant='success'] :is(.Snackbar_iconContainer__XHtge .Snackbar_icon__cuoLQ) {
      fill: #12b76a;
      color: #ecfdf3;
    }

[data-variant='error'] :is(.Snackbar_iconContainer__XHtge .Snackbar_icon__cuoLQ) {
      fill: #f04438;
      color: #fef3f2;
    }

.Snackbar_iconContainer__XHtge .Snackbar_image__t2Xgl {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

.Snackbar_textContainer__joZo7 {
  flex: auto;
}

.Snackbar_textContainer__joZo7 .Snackbar_title__CowLN {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--font-openSans);
    color: #344054;
}

.Snackbar_textContainer__joZo7 .Snackbar_message__CBW_0 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: var(--font-openSans);
  overflow-wrap: break-word;
}

.Snackbar_buttonWithText__0FmyV {
  padding: 9.5px 0.75rem;
  font-weight: 600;
}

.Snackbar_crossButton__9baaJ {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  padding: 4px;
  height: 32px;
  width: 32px;

  transition: background-color 0.08s ease-in-out;
}

.Snackbar_crossButton__9baaJ:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

.Snackbar_crossButton__9baaJ:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

.Snackbar_crossButton__9baaJ > span {
    display: flex;

    justify-content: center;
    align-items: center;
  }

.Snackbar_crossButton__9baaJ .Snackbar_crossIcon__QfVo_ {
    width: 24px;
    height: 24px;
    color: #344054;
  }

.Snackbar_ToastViewport___y3wz {
  --viewport-padding: 25px;
  position: fixed;
  transform: translateX(-50%);
  top: 8%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.Snackbar_ToastRoot__iHwle[data-state='open'] {
    animation: Snackbar_slideIn__gnH2_ 250ms cubic-bezier(0.16, 1, 0.3, 1);
  }

.Snackbar_ToastRoot__iHwle[data-state='closed'] {
    animation: Snackbar_hide__itnVo 150ms ease-in;
  }

@keyframes Snackbar_hide__itnVo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes Snackbar_slideIn__gnH2_ {
  from {
    transform: translateY(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateY(0);
  }
}

.Alert_alert__UJnCO {
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 1rem;
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  display: flex;
  position: relative;
}

[data-variant='info'].Alert_alert__UJnCO {
  background-color: #f5faff;
  border-color: #2e90fa;
}

[data-variant='warning'].Alert_alert__UJnCO {
  background-color: #fffcf5;
  border-color: #f79009;
}

[data-variant='error'].Alert_alert__UJnCO {
  background-color: #fffafa;
  border-color: #f04438;
}

[data-variant='success'].Alert_alert__UJnCO {
  background-color: #f6fef9;
  border-color: #12b76a;
}

.Alert_title__dTpae {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--font-openSans);
  margin-bottom: 4px;
  color: #344054;
}

.Alert_description__ocoZO {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: var(--font-openSans);
  color: #344054;
}

.Alert_icon__cRWiZ {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.Alert_icon__cRWiZ svg {
  color: white;
  width: 1rem;
  max-height: 1rem;
}

[data-variant='info'] .Alert_icon__cRWiZ {
    background-color: #2e90fa;
  }

[data-variant='warning'] .Alert_icon__cRWiZ {
    background-color: #f79009;
  }

[data-variant='error'] .Alert_icon__cRWiZ {
    background-color: #f04438;
  }

[data-variant='success'] .Alert_icon__cRWiZ {
    background-color: #12b76a;
  }

.Alert_closeIcon__Sapsw {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/faac4ac11aa3d97b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Open_Sans_9c011f';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3d9ea938b6afa941-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Open_Sans_Fallback_9c011f';src: local("Arial");ascent-override: 101.65%;descent-override: 27.86%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_9c011f {font-family: '__Open_Sans_9c011f', '__Open_Sans_Fallback_9c011f';font-style: normal
}.__variable_9c011f {--font-openSans: '__Open_Sans_9c011f', '__Open_Sans_Fallback_9c011f'
}

.Checkbox_wrapper__SvOM8 {
  display: inline-block;
  position: relative;
  overflow-wrap: break-word;
}

.Checkbox_icon__LqiJL {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  pointer-events: none;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 1px 0px #0000000d, 0px 0px 1px 0px #00000040;
  color: #ffffff;
}

.Checkbox_caption__DVMb8 {
  display: block;
  margin-left: 0.5rem;
  color: #344054;
  text-align: left;
  word-break: break-word;
}

.Checkbox_input__yjFlK {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.Checkbox_input__yjFlK:disabled {
    cursor: not-allowed;
  }

.Checkbox_input__yjFlK:not(:checked):not(:indeterminate) + span {
    border-color: #818996;
  }

.Checkbox_input__yjFlK:not(:checked):not(:indeterminate) + span svg {
    display: none;
  }

.Checkbox_input__yjFlK:checked + span,
  .Checkbox_input__yjFlK:indeterminate + span {
    border-color: #ffffff;
    background-color: #2b50c8;
  }

.Checkbox_input__yjFlK:disabled ~ .Checkbox_caption__DVMb8 {
    color: #a8adb7;
  }

.Checkbox_input__yjFlK:disabled + span {
    background-color: #f5f6f9;
    border-color: #a8adb7;
  }

.Checkbox_input__yjFlK:checked:disabled + span,
  .Checkbox_input__yjFlK:indeterminate:disabled + span {
    background-color: #d5dcf4;
    border-color: #d5dcf4;
  }

.Checkbox_wrapper__SvOM8[data-size='small'] {
    min-width: 1rem;
    min-height: 1rem;
    padding-left: 1rem;
  }

[data-size='small'] .Checkbox_icon__LqiJL {
    width: 1rem;
    height: 1rem;
  }

[data-size='small'] .Checkbox_caption__DVMb8 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: var(--font-openSans);
    line-height: 1rem;
}

.Checkbox_wrapper__SvOM8[data-size='large'] {
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding-left: 1.5rem;
  }

[data-size='large'] .Checkbox_icon__LqiJL {
    width: 1.5rem;
    height: 1.5rem;
  }

[data-size='large'] .Checkbox_caption__DVMb8 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: var(--font-openSans);
    line-height: 1.5rem;
}

